import React, { useRef, useState, useEffect } from "react"
import * as styles from "../styles/index.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectsGrid = ({ projects, count, setCount, setPrevCount, setIndexPanel}) => {
  
 

  const container = useRef()
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [firstClientX, setFirstClientX] = useState(0)

  

  const handleMouseDown = (e) => {
    setIsScrolling(true)
    setClientX(e.clientX)
    setFirstClientX(e.clientX)
  }
  const handleMouseUp = (newCount) => {
    if (clientX !== firstClientX ){
      setIsScrolling(false)
    } else {
      setPrevCount(count)
      setCount(newCount)
      setIndexPanel(false)
      console.log(count + "/" + newCount)
    }    
  }

  const handleMouseMove = (e) => {
    if (isScrolling){
      container.current.scrollLeft = container.current.scrollLeft - e.clientX + clientX
      setClientX(e.clientX)
    }
  }

  useEffect(() => {
    container.current.scrollLeft = 0
  }, [projects])

  

  return (
    <div ref={container} className={styles.imgContainer} >
        {projects.map(({node}) => {
        const ratio = node.figures[0].src.childImageSharp.gatsbyImageData.width / node.figures[0].src.childImageSharp.gatsbyImageData.height
        const classRatio =  `${ratio < 1 ? styles.portrait : styles.paysage}`
        return (
            <>             
                            <div className={`${styles.project} ${classRatio}`} 
                              onMouseDown={handleMouseDown}
                              onMouseUp={() => handleMouseUp(node.index)}
                              onMouseMove={handleMouseMove}
                              >  
                              
                             
                              <GatsbyImage 
                                image={getImage(node.figures[0].src)} 
                                alt={node.figures[0].name} 
                                objectFit="CONTAIN"
                                style={{height: `100%`}}
                                className={styles.image}
                                draggable={false}
                              />
                               <p>
                                {node.title}<br />
                                {node.field}, <span>{node.date}</span>
                              </p>
                            </div>
                               
            </>
        )
        })}
    </div>
  )
}

export default ProjectsGrid