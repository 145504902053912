import React, {useRef, useEffect, useState} from "react"
import * as styles from "../styles/cartel.module.scss"
import MarqueeItem from "./marquee-item"
import { useWindowSize } from "../hooks/useWindowSize"

const Marquee = ({project, thisGlobalProject}) => {
  const view = useWindowSize()
  const marqueeId = useRef(null)
  const [duration, setDuration] = useState(0)

  // Get desktop variable on document load
  const [hasMounted, setHasMounted] = useState(false);
  
  useEffect(() => {
    setHasMounted(true);
  }, [])

  useEffect(() => {
    setDuration(marqueeId.current.offsetWidth / 100)
  }, [view.width, hasMounted]);
  
  return (
          <div className={styles.marqueeContainer} ref={marqueeId} style={{animationDuration: duration + "s"}}>
              <div className={styles.marquee}>
                { [1,2,3,4].map(() => {
                  return (
                    <>
                      {(thisGlobalProject && thisGlobalProject.title === project.title) &&
                      <>
                        <MarqueeItem project={thisGlobalProject} />
                      </>
                      }
                      {(thisGlobalProject && thisGlobalProject.title !== project.title) && 
                        <>
                          <MarqueeItem project={project} />
                          <MarqueeItem project={thisGlobalProject} part={true} />
                        </>
                      }
                      {!thisGlobalProject && 
                      <>
                          <MarqueeItem project={project} />
                        </>
                      }
                    </>
                  )
                })}
              </div>
          </div>
  )
}

export default Marquee