// extracted by mini-css-extract-plugin
export var cartelContainer = "cartel-module--cartel-container--mBShp";
export var cartelCurrent = "cartel-module--cartel-current--5Gkew";
export var cartelOpened = "cartel-module--cartel-opened--jjo0z";
export var header = "cartel-module--header--7tR6j";
export var icon = "cartel-module--icon--OvTHM";
export var indexOpen = "cartel-module--index-open--BFPyC";
export var marquee = "cartel-module--marquee--Ptm+M";
export var marqueeContainer = "cartel-module--marquee-container--LjBrp";
export var marqueeItem = "cartel-module--marquee-item--1laXX";
export var marqueelike = "cartel-module--marqueelike--1QNOL";
export var popupVideo = "cartel-module--popup-video--w2Q6w";
export var showVideo = "cartel-module--show-video--UfOk-";
export var text = "cartel-module--text--bALQn";
export var textContainer = "cartel-module--text-container--a8PZI";
export var typingTitle = "cartel-module--typing-title--tGj-D";