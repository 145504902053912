import React, {useState, useEffect} from "react"
import * as styles from "../styles/clock.module.scss"

function Clock({state}) {  
    
  const initialTime = new Date();
  const [time, setTime] = useState(initialTime);

  useEffect( () => {
    const id = setInterval( () => {
      setTime( () => new Date() );
    }, 1000);
    return () => clearInterval(id);
  }, []);

  const secondsStyle = {
    transform: `rotate(${time.getSeconds() * 6}deg) translate(-50%, -100%)`
  }

  const minutesStyle = {
    transform: `rotate(${time.getMinutes() * 6}deg) translate(-50%, -100%)`
  }

  const hoursStyle = {
    transform: `rotate(${time.getHours() * 30}deg) translate(-50%, -100%)`
  }

    return (
        <>
          <div className={styles.clock}>
            <div className={`${styles.aiguille} ${styles.hours}`} style ={hoursStyle}>{time.getHours().toString().padStart(2,'0')}</div>
            <div className={`${styles.aiguille} ${styles.minutes}`} style ={minutesStyle}>{time.getMinutes().toString().padStart(2,'0')}</div>
            <div className={`${styles.aiguille} ${styles.seconds}`} style ={secondsStyle}>{time.getSeconds().toString().padStart(2,'0')}</div>
          </div>
        </>
    );
  }
  
export default Clock;