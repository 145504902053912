import React from "react"
import FilterButton from "./filtersButton"

const FiltersRemainingFirst = ({
    addFilter,
    remainingFilteredCategories, 
 }) => {
    
    return (
        <>  
            <FilterButton key="filters_categories" data={remainingFilteredCategories} field="category" handleClick={addFilter} />
        </>
    )
}

export default FiltersRemainingFirst