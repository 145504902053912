import React, {useState, useEffect} from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useWindowSize } from "../hooks/useWindowSize"
import Cartel from "./cartel"
import * as styles from "../styles/slideshow.module.scss"


const Slideshow = ({projects, globalProjects, count, setCount, prevCount, setPrevCount, indexPanel}) => {

  const [curProject, setCurProject] = useState(0)
  const [projectOpened, setProjectOpened] = useState(false)

  let indexImg = 0;  

  const view = useWindowSize()

  const lengthProject = projects.edges.length

  const imagesLengthFn = () => {
    let count = 0;
    projects.edges.map( ({node}) => {
      return count += node.figures.length
    })
    return count
  }
  const imagesLength = imagesLengthFn()

  let indexproject = 0
  projects.edges.map(({node}, index) => {
    node["position"] = (index + 1) + "/" + projects.edges.length
    node["index"] = indexproject
    indexproject = indexproject + node.figures.length
  })
  
  const handleNext = () => {
    setPrevCount(count)
    if (count === imagesLength -1){
      setCount(0)
    } else {
      setCount(count+1)
    }
  }

  const handlePrev = () => {
    setPrevCount(count)
    if (count === 0){
      setCount(imagesLength - 1)
    } else {
      setCount(count-1)
    }
    
  }

  // Swipe slideshow

  const isTouchFn = () => {
    try {
      document.createEvent("TouchEvent");
      return true
    } catch (e){
      return false;
    }
  }
  const isTouch = isTouchFn()
  
  const [isSwiping, setIsSwiping] = useState(false)
  const [touchStart, setTouchStart] = useState(0)
  const [touchDelta, setTouchDelta] = useState(0)

  const handleSlideTouchStart = (e) => {
    setIsSwiping(true)
    setTouchStart(e.touches[0].pageX)
    setTouchDelta(0)
  }

  const handleSlideTouchMove = (e) => {
    setTouchDelta((e.touches[0].pageX - touchStart) * 100 / view.width )
  }

  const handleSlideTouchEnd = (e) => {
    setIsSwiping(false)
    const touchDirection = touchDelta < 0 ? "next" : "prev"
    if (touchDirection === "next" && touchDelta !== 0){
      handleNext()
    } else if (touchDirection === "prev" && touchDelta !== 0) {
      handlePrev()
    }
    setTouchDelta(0)
  }

  // Pour avoir la variable desktop on document load
  const [hasMounted, setHasMounted] = useState(false);
  
  useEffect(() => {
    setHasMounted(true);
  }, [])

  if (!hasMounted) {
    return null;
  }


  return (
    <>  
       <div className={styles.slideshow}>
        { projects.edges.map(({node}, indexproject) => {
          const project = node
          return (
            <>

              {project.figures.map( (item) => {
                  if (count === indexImg && indexproject !== curProject){
                    setCurProject(indexproject)
                  }
                  const ratio = item.src.childImageSharp.gatsbyImageData.width / item.src.childImageSharp.gatsbyImageData.height
                  const classRatio =  `${ratio <= 1 ? styles.portrait : styles.paysage}`
                  const styleSlide =  view.desktop ? { width: 62 * ratio + "vh"} : ( ratio > 1 ? { height: 80 / ratio + "vw"} : { height: 50 / ratio + "vw"} )
                  const classLoop = `${(count === 0 && indexImg === imagesLength - 1 ) ? styles.loopPrev : ((count === imagesLength -1 && indexImg === 0) ? styles.loopNext : "")}`
                  const disabledStack = `${ (indexImg === count || indexImg === prevCount) ? "" : styles.disableAnimation}`
                  const classSlide = `${styles.slide} ${classRatio} ${count === indexImg ? styles.current: (count > indexImg ? (styles.prevStack) : (styles.nextStack) )} ${disabledStack}`
                  indexImg++
                  return (
                    <>
                      {((indexImg -1 > count - 2 && indexImg -1 < count + 2) || (count === 0 && indexImg === imagesLength ) || (count === imagesLength - 1 && indexImg - 1 === 0) || indexImg -1 === prevCount || indexPanel) &&
                        <>
                         <div className={`${classSlide} ${classLoop} ${styles.slideBack} ${!item.cache ? styles.slideBackBlack : "" }`} style={styleSlide}>
                          {(item.cache) &&
                            <GatsbyImage 
                              image={getImage(item.cache)} 
                              alt={item.name} 
                              objectFit="CONTAIN"
                              class={styles.cache}
                              style={{height: `100%`}}
                            />
                          }
                         </div>
                         
                          <div className={`${classSlide} ${classLoop} ${styles.slideBlend}`} style={styleSlide}> 
                                {item.video &&
                                  <>
                                    <video loop playsInline autoPlay muted className={styles.player}>
                                      <source src={item.video.publicURL} type="video/mp4"></source>
                                    </video>
                                  </>
                                }
                                
                                {(!item.video) &&
                                  <GatsbyImage 
                                    image={getImage(item.src)} 
                                    alt={item.name} 
                                    objectFit="CONTAIN"
                                    style={{height: `100%`}}
                                  />
                                }
                          </div>
                        </>
                      }
                     
                    </>               
                  )
              })}

            <Cartel 
              project={project} 
              globalProjects={globalProjects.edges} 
              curProject={curProject} 
              index={indexproject} 
              setProjectOpened={setProjectOpened} 
              projectOpened={projectOpened} 
              lengthProject={lengthProject} 
              isTouch={isTouch}
              indexPanel={indexPanel}
            />

            { (project.link) &&
              <a className={`${styles.advertising} ${curProject === indexproject  && !indexPanel? styles.advertisingActive : "" }`} href={project.link} target="blank">
                <svg x="0px" y="0px" viewBox="0 0 84.4 83.8">
                    <path class="st0" d="M78,62.4c-1.1,1.9-7.7-0.1-9.1,1.5c-1.4,1.7,1.7,7.7,0.1,9.1c-1.7,1.4-7.2-2.7-9.1-1.6
                      c-1.9,1.1-1.1,7.8-3.1,8.5c-2.1,0.7-5.9-4.9-8.1-4.6c-2.1,0.3-3.7,6.9-5.9,6.9c-2.2,0-3.9-6.6-6.1-7c-2.1-0.4-5.9,5.2-8,4.4
                      c-2-0.8-1.4-7.4-3.3-8.6c-1.9-1.1-7.4,2.8-9.1,1.4c-1.7-1.4,1.2-7.4-0.2-9.1c-1.4-1.7-8,0.1-9.1-1.8c-1.1-1.9,3.8-6.5,3-8.5
                      c-0.8-2-7.6-2.6-8-4.7c-0.4-2.1,5.8-4.8,5.8-6.9c0-2.1-6.3-4.9-6-7.1c0.3-2.1,7.2-2.4,7.9-4.5c0.7-2-4.3-6.7-3.2-8.6
                      c1.1-1.9,7.7,0.1,9.1-1.5c1.4-1.7-1.7-7.7-0.1-9.1c1.7-1.4,7.2,2.7,9.1,1.6c1.9-1.1,1.1-7.8,3.1-8.5c2.1-0.7,5.9,4.9,8.1,4.6
                      c2.1-0.3,3.7-6.9,5.9-6.9c2.2,0,3.9,6.6,6.1,7c2.1,0.4,5.9-5.2,8-4.4c2,0.8,1.4,7.4,3.3,8.6c1.9,1.1,7.4-2.8,9.1-1.4
                      c1.7,1.4-1.2,7.4,0.2,9.1c1.4,1.7,8-0.1,9.1,1.8c1.1,1.9-3.8,6.5-3,8.5c0.8,2,7.6,2.6,8,4.7c0.4,2.1-5.8,4.8-5.8,6.9
                      c0,2.1,6.3,4.9,6,7.1c-0.3,2.1-7.2,2.4-7.9,4.5C74.1,55.8,79.1,60.5,78,62.4"/>
                </svg>

                Try <br />me
              </a>


            }
            </>
          )

        })}

        <div 
            className={styles.controlContainer}
            onTouchStart={isTouch && projectOpened === false ? handleSlideTouchStart : ""} 
            onTouchMove={isTouch && projectOpened === false ? handleSlideTouchMove : ""} 
            onTouchEnd={isTouch && projectOpened === false?  handleSlideTouchEnd : ""}
          >
            {(projectOpened === false && !indexPanel) &&
                <>
                  <div onClick={() => count > handlePrev()} className={`${styles.control} ${styles.controlPrev}`} ></div>
                  <div onClick={() => handleNext()} className={`${styles.control} ${styles.controlNext}`}></div>
                </>
            }
        </div>
      </div>

    
    </>
  )
}

export default Slideshow