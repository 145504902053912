import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Filters from "./filters"
import IndexGrid from "./indexGrid"
import * as styles from "../styles/index.module.scss"

const IndexView = ({allProjects, setCount, setIndexPanel, setPrevCount, count }) => {

  const {allCategories} = useStaticQuery(graphql`
    {
      allCategories: allCategoriesJson {
        edges {
          node {
            name
          }
        }
      }
    }
  `);
  
  let savedFolio = 0
  const folioProjects = [ allProjects.map(({node}) => {
    const folio = savedFolio
    savedFolio = savedFolio + node.figures.length
    return {
      "title": node.title,
      "index": folio
    }
  }) ]

  const [state, setState] = useState({
    filteredData: [],
    query: [],
  })
  
  const searchEngine = (name, autosearch) => {
    const prevQueries = state.query
    const query = (autosearch === true) ? name : ((name !== "remove") ? [...prevQueries, name] : prevQueries)
    const projects = allProjects || []
    const filteredData = projects.filter((project) => {
      const categories = project.node.categories.map(category => category.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      return (
        query.every(v => categories.includes(v.normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
      )
    })
    setState({
      filteredData,
      query
    })
  }

  const addFilter = (event, name) => {
    event.preventDefault()
    searchEngine(name)
  }

  const removeFilter = (event, name) => {
    event.preventDefault()
    state.query = state.query.filter(query => !query.includes(name))    
    searchEngine("remove")
  }

  const {filteredData, query} = state
  const hasSearchResults = filteredData && query.length !== 0
  const projects = hasSearchResults ? filteredData : allProjects

  
  function getFiltered(query1,query2,query3){
    const findRemainingFoo = projects.map(({node}) => node.categories)
    const orderRemainingFoo = new Set([].concat(...findRemainingFoo))
    const remainingFoo = Array.from(orderRemainingFoo)

    const allFilteredFoo = allCategories.edges.filter(({node}) => remainingFoo.includes(node[query2].normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
    const clickedFilteredFoo = allFilteredFoo.filter(({node}) => query.includes(node[query2].normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
    const allRemainingFilteredFoo = (projects.length > 1 ) ? allFilteredFoo.filter(({node}) => !query.includes(node[query2].normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) : []
    //= ["speculative design", "critical design"]
    const remainingFilteredFoo = allRemainingFilteredFoo //.filter(({node}) => !superCategory.includes(node[query2].normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
    //const remainingFilteredSuperCategory = allRemainingFilteredFoo.filter(({node}) => superCategory.includes(node[query2].normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
    
    return {clicked:clickedFilteredFoo, remaining:remainingFilteredFoo}
  }

  const categories = getFiltered("categories","name","allCategories")
  // const clients = getFiltered("clients","name","allStrapiClient")
  // const domaines = getFiltered("domaines",`name_${lang}`,"allStrapiDomaine")

 

  return (
    <div className={`${styles.wrapper}`} >
      <Filters
        addFilter={addFilter}
        removeFilter={removeFilter}
        remainingFilteredCategories={categories.remaining}
        clickedFilteredCategories={categories.clicked}
      />
      <IndexGrid projects={projects} folioProjects={folioProjects} setCount={setCount} setIndexPanel={setIndexPanel} setPrevCount={setPrevCount} count={count}/>
    </div>
  )
}

export default IndexView