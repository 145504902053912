import {useEffect, useState} from "react"

const getWidth = () => typeof window !== `undefined` ? window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth 
    : 900
const getHeight = () => typeof window !== `undefined` ? window.innerHeight
|| document.documentElement.clientHeight
|| document.body.clientHeight 
: 900

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: getWidth(),
        height: getHeight(),
        desktop: getWidth() > 980 ? true : false,
    })

    useEffect( () => {
        let timeoutId = null
        const resizeListener = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => setWindowSize({
                width: getWidth(),
                height: getHeight(),
                desktop: getWidth() > 980 ? true : false,
            }), 250)
        }
        window.addEventListener("resize", resizeListener)
        return () => window.removeEventListener("resize", resizeListener)
    }, [])
    return windowSize
}