import React, {useEffect, useRef} from "react"
import Clock from "../components/clock.js"
import * as styles from "../styles/header.module.scss"
import infosIcon from "../assets/infos.svg"
import indexIcon from "../assets/index.svg"
import IndexView from "./indexView"

const Header = ({aboutModal, allProjects, setCount, setIndexPanel, indexPanel, count, setPrevCount}) => {
  const index = useRef()
  const btnIndex = useRef()

  const updateIndex = () => {
    if (indexPanel) {
      setIndexPanel(false)
    } else {
      setIndexPanel(true) 
    }
    
  }

  const handleClickOutside = e => {
    if (index.current.contains(e.target) || btnIndex.current.contains(e.target)){
      return
    } 
    updateIndex()
  }

  useEffect( () => {
      if(indexPanel){
          document.addEventListener("mousedown", handleClickOutside)
      } else {
          document.removeEventListener("mousedown", handleClickOutside)
          
      }
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      }
     
  }, [indexPanel])
  return (
    <div className={styles.header}>
      <div className={styles.headerContainer}>
        <h1>Salomé Macquet</h1>
        <div className={styles.clocksContainer}>
          <Clock />
          <Clock />
        </div>
        <div className={styles.mainText}>
        The cover announces <i>The taste of waiting</i> and the conversation begins. We discuss time, astrophysics and quantum physics… Soon we run out of words to describe the overwhelming complexity of the universe; language fails us. At that point art, science and speculative design all intertwine. They open up a new dialogue between interfaces, books, installations, algorithms and spectators, helping us examine our relationship with time. The imaginary breaks through. Worlds are unveiled. We exist there in that moment and glimpse a myriad of possibilities. But, looking sideways, the exploration moves on leaving language now the richer.&nbsp;<img className={styles.icon} src={infosIcon} alt="Open infos" onClick={() => aboutModal()} />&nbsp;<img className={styles.icon} src={indexIcon} alt="Open Index" ref={btnIndex} onClick={() => setIndexPanel(!indexPanel)} />
        </div>
      </div>
      {indexPanel === true &&
        <div ref={index} >
          <IndexView allProjects={allProjects} setCount={setCount} setIndexPanel={setIndexPanel} count={count} setPrevCount={setPrevCount} />
        </div>
      }
    </div>
  )
}

export default Header