import React from "react"
import FilterButton from "./filtersButton"

import * as styles from "../styles/filters.module.scss"

const FiltersSelected = ({
  removeFilter,
  clickedFilteredCategories,
  remainingFilterIsEmpty
 }) => {
     
    return (
        <>
            <FilterButton key="clickedfilters_categories" data={clickedFilteredCategories} field="category" handleClick={removeFilter} selected={true} />

                {clickedFilteredCategories.length > 0 
                && remainingFilterIsEmpty === false  && (
                    <div className={styles.arrow}>→</div>
                )
                }
        </>
    )
}

export default FiltersSelected