import React from "react"
import * as styles from "../styles/filters.module.scss"

const FiltersButton = ({data, handleClick, selected}) => {

    return (
        <>
            {data.map(({node}) => { 
                return (
                    <div className={`${styles.filter} ${(selected ) ? `${styles.isSelected}` : ""}`} onClick={ (event) => handleClick(event, node["name"].normalize("NFD").replace(/[\u0300-\u036f]/g, ""))} >
                        {node["name"]}
                        {selected === true ? <span>×</span> : ""}
                    </div>
                )
            })}
        </>
    )
}

export default FiltersButton