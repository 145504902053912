import React, {useEffect, useState} from "react"
import * as styles from "../styles/cartel.module.scss"

const Typing = ({title, indexProject, lengthProject}) => {
  const text = title + " (" + (indexProject + 1) + "/" + lengthProject + ") → read more"
  const [index, setIndex] = useState(0);
  const [isTime, setIsTime] = useState(false)

  useEffect (() => {
    const timer = setTimeout(() => {
      setIsTime(true)
    }, 1000);
    return () => clearTimeout(timer);
  })
  useEffect( () => {
    const id = setInterval( () => {
        if (index < text.length && isTime){
          setIndex(index + 1)
        } else {
          return () => clearInterval(id);
        }
    }, 70);
    return () => clearInterval(id);
  }, [index, isTime]);

  return (
          <div className={styles.typingTitle}>
               {text.substring(0, index)}
          </div>
  )
}

export default Typing