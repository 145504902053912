import React from "react"
import * as styles from "../styles/cartel.module.scss"
import prizeIcon from "../assets/prize.svg"

const MarqueeItem = ({project, part}) => {
  return (
    <>
        { part && 
          <div className={styles.marqueeItem}><i>part of</i></div>
        }
                      <h2 className={styles.marqueeItem}>{project.title}</h2>
                      {project.field &&
                        <div className={styles.marqueeItem}>{project.field}</div>
                      }
                      {project.prize &&
                          <>
                            {
                            project.prize.map( (prize) => {
                              return (
                                <div className={styles.marqueeItem}><img className={styles.icon} src={prizeIcon} alt="Prize icon" />&ensp;{prize}</div>
                              )
                            })}
                          </>
                        }
                      {project.client &&
                        <div className={styles.marqueeItem}>for {project.client}</div>
                      }
                      {project.date &&
                        <div className={`${styles.marqueeItem}`}>{project.date}</div>
                      }
                      {project.team &&
                        <div className={styles.marqueeItem}>with {project.team}</div>
                      }
                      
                        
                        { project.meta &&
                          <>
                            {
                            project.meta.map( (meta) => {
                              return (
                                <div className={styles.marqueeItem}>{meta}</div>
                              )
                              })}
                          </>
                        }
    </>
  )
}

export default MarqueeItem