import React from "react"
import FiltersDesktop from './filtersDesktop'
import FiltersSmartphone from './filtersSmartphone'
import { useWindowSize } from "../hooks/useWindowSize"
import * as styles from "../styles/filters.module.scss"

const Filters = ({
  addFilter,
  removeFilter,
  remainingFilteredCategories, 
  clickedFilteredCategories, 
 }) => {

  const size = useWindowSize()
  const remainingFilterIsEmpty = (remainingFilteredCategories.length > 0 ) ? false : true

  return(
  <>

      <nav className={styles.navbar}>

        {!size.desktop &&(
          <>
            <FiltersSmartphone 
              clickedFilteredCategories={clickedFilteredCategories} 
              removeFilter={removeFilter}
              remainingFilterIsEmpty={remainingFilterIsEmpty}
              addFilter={addFilter}
              remainingFilteredCategories= {remainingFilteredCategories}
            />
          </>
        )}
        
        {size.desktop && (
          <>
              <FiltersDesktop
                clickedFilteredCategories={clickedFilteredCategories} 
                removeFilter={removeFilter}
                remainingFilterIsEmpty={remainingFilterIsEmpty}
                addFilter={addFilter}
                remainingFilteredCategories= {remainingFilteredCategories}
              />
          </>
        )}

      </nav>
  </>
  )
}

export default Filters
