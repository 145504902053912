// extracted by mini-css-extract-plugin
export var advertising = "slideshow-module--advertising--w4B7i";
export var advertisingActive = "slideshow-module--advertising-active--wp5eS";
export var cache = "slideshow-module--cache--8tRXj";
export var control = "slideshow-module--control--VDBlW";
export var controlContainer = "slideshow-module--control-container--+DtVY";
export var controlFull = "slideshow-module--control-full--YDCe5";
export var controlNext = "slideshow-module--control-next--bUmWO";
export var controlPrev = "slideshow-module--control-prev--SwMhu";
export var current = "slideshow-module--current--9qhQU";
export var disableAnimation = "slideshow-module--disable-animation--TbAdz";
export var loopNext = "slideshow-module--loopNext--xAlby";
export var loopPrev = "slideshow-module--loopPrev--GxevY";
export var marqueelike = "slideshow-module--marqueelike--NhLpz";
export var nextStack = "slideshow-module--nextStack--TiBS2";
export var paysage = "slideshow-module--paysage--mJqlF";
export var player = "slideshow-module--player--TQmHg";
export var portrait = "slideshow-module--portrait--u6jZ1";
export var prevStack = "slideshow-module--prevStack--yo7ja";
export var slide = "slideshow-module--slide--6T8xs";
export var slideBack = "slideshow-module--slide-back--9U3FB";
export var slideBackBlack = "slideshow-module--slide-back-black--cGWgs";
export var slideBlend = "slideshow-module--slide-blend--ZYm8-";
export var slideshow = "slideshow-module--slideshow--eQi2L";