import React, {useState} from "react"
import Layout from "../components/layout"
import Slideshow from "../components/slideshow.js"
import Header from "../components/header.js"
import About from "../components/about.js"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {

  const {projects, globalProjects} = useStaticQuery(graphql`
    {
      projects: allSlidesJson {
        edges {
          node {
            title
            date
            client
            field
            team
            prize
            meta
            figures {
              name
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 3000
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              },
              cache {
                childImageSharp {
                  gatsbyImageData(
                    width: 3000
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              video {
                publicURL
              }
            }
            categories
            text
            iframe
            global_project
            link
          }
        }
      }
      globalProjects: allGlobalProjectsJson {
        edges {
          node {
            id
            title
            date
            field
            team
            prize
            meta
            text
          }
        }
      }
    }
  `);

  const [indexPanel, setIndexPanel] = useState(false)
  const [count, setCount] = useState(0)
  const [prevCount, setPrevCount] = useState(-1)

  const [about, setAbout] = useState(false)
  const aboutModal = () => {
    setAbout(!about)
    return
  }
  return (
    <>
      <Layout >
        <Header aboutModal={aboutModal} allProjects={projects.edges} setCount={setCount} setIndexPanel={setIndexPanel} indexPanel={indexPanel} setPrevCount={setPrevCount} count={count} />
        <Slideshow projects={projects} globalProjects={globalProjects} count={count} setCount={setCount} indexPanel={indexPanel} prevCount={prevCount} setPrevCount={setPrevCount} />
        <About about={about} aboutModal={aboutModal} />
      </Layout>
    </>
  )
}

export default IndexPage
