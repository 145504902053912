import React, {useRef, useEffect, useState} from "react"
import * as styles from "../styles/cartel.module.scss"
import Marquee from "./marquee"
import Typing from "./typing"
import ReactPlayer from 'react-player'

const Cartel = ({project, globalProjects, curProject, projectOpened, index, setProjectOpened, lengthProject, isTouch, indexPanel}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const isCurProject = curProject === index ? true : false
  const cartelId = useRef()

  const updateCartel = () => {
    if (projectOpened) {
      setProjectOpened(false) 
      setIsPlaying(false)
    } else {
      setProjectOpened(true) 
    }
    
  }

  const handleClickOutside = e => {
    if (cartelId.current.contains(e.target)){
      return
    } 
    updateCartel()
  }

  useEffect( () => {
      if(projectOpened && isCurProject){
          if (isTouch){
            document.addEventListener("touchstart", handleClickOutside)
          } else { 
            document.addEventListener("mousedown", handleClickOutside)
          }
          
      } else {
          document.removeEventListener("mousedown", handleClickOutside)
          document.removeEventListener("touchstart", handleClickOutside)
      }
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
          document.removeEventListener("touchstart", handleClickOutside)
      }
     
  }, [projectOpened, isCurProject])


  const findRelatedGlobalProject = () => {
    let data
    globalProjects.map(({node}) => {
      if (node.title === project.global_project) {
        data = node
      }
      return
    })
    return data
  }
  const thisGlobalProject = findRelatedGlobalProject()


  return (
    <>
      <div ref={cartelId} key={index} className={`${styles.cartelContainer} ${isCurProject ? styles.cartelCurrent : ""} ${indexPanel ? styles.indexOpen : ""} ${projectOpened && isCurProject === true ? styles.cartelOpened : ""}`} >

          <div className={styles.header} onClick={!isTouch ? updateCartel : "" } onTouchStart={isTouch ? updateCartel : "" } >
            <Marquee project={project} thisGlobalProject={thisGlobalProject} index={index} />
            
            { (isCurProject) &&
              <Typing title={project.title} indexProject={index} lengthProject={lengthProject} />
            }
          </div>
            
          { (project.text || thisGlobalProject ) && 
            <div className={styles.textContainer}>
              { project.text &&
                <div className={styles.text}>{project.text}</div>
              }

              {(thisGlobalProject && thisGlobalProject.title === project.global_project && thisGlobalProject.text) &&
                <div className={styles.text}>{thisGlobalProject.text}</div>
              }
            </div>
          }
      </div>

      { (project.iframe && isCurProject) &&
        <div className={`${styles.popupVideo} ${projectOpened === true && isCurProject ? styles.showVideo : ""}`}>
          <ReactPlayer 
            key={index}
            url={project.iframe}
            controls="true"
            playing={projectOpened && isPlaying ? true : false}
            onPlay={() => setIsPlaying(true)}
            onPaused={() => setIsPlaying(false)}
            onEnded={() => setIsPlaying(false)}
            config={{
              vimeo: {
                playerOptions: {texttrack: "en", title: 0, byline: 0, portrait: 0, playsinline: 1}
              }
            }}
          />
       
        </div>
      }
    </>
  )
}

export default Cartel