
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Layout = ({ children, title, slug}) => {

  return (
    <>
      <Helmet>
        <title>Salomé Macquet</title>
        <meta name="description" content="Research, design and typography" />
        <meta name="keywords" content="speculative design, art direction, creative coding, digital, books, ux, ui, identity, brussels, paris" />
        <meta property="og:title" content="Salomé Macquet" />
        <meta name="author" content="Salomé Macquet" />
        <link rel="canonical" href="https://salomemacquet.com" />
        <html lang="en" />
      </Helmet>

      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
