import React from "react"
import FiltersSelected from "./FiltersSelected"
import FiltersRemainingFirst from "./FiltersRemainingFirst"
import * as styles from "../styles/filters.module.scss"

const FiltersDesktop = ({
    addFilter,
    remainingFilteredCategories, 
    clickedFilteredCategories,
    removeFilter,
    remainingFilterIsEmpty
 }) => {
         
    return (
        <>  
            <div className={`${styles.container} ${styles.selected}`}>
              <FiltersSelected 
                  clickedFilteredCategories={clickedFilteredCategories} 
                  removeFilter={removeFilter}
                  remainingFilterIsEmpty={remainingFilterIsEmpty}
                />
            </div>        
            <div className={`${styles.container} ${styles.remaining}`}>
                    <FiltersRemainingFirst 
                        addFilter={addFilter}
                        remainingFilteredCategories= {remainingFilteredCategories}
                    />
            </div>
        </>
    )
}

export default FiltersDesktop