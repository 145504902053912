import React from "react"
import FiltersSelected from "./FiltersSelected"
import FiltersRemainingFirst from "./FiltersRemainingFirst"

import * as styles from "../styles/filters.module.scss"

const FiltersSmartphone = ({
    addFilter,
    remainingFilteredCategories, 
    clickedFilteredCategories,
    removeFilter,
    remainingFilterIsEmpty
 }) => {

    const firstLine = remainingFilteredCategories.length + clickedFilteredCategories.length

    return (
        <>  
            {firstLine > 4 &&(
                <>
                    <div className={`${styles.list} ${styles.group}`}>
                        <FiltersSelected 
                            clickedFilteredCategories={clickedFilteredCategories} 
                            removeFilter={removeFilter}
                            remainingFilterIsEmpty={remainingFilterIsEmpty}
                        />
                            
                        <FiltersRemainingFirst 
                            addFilter={addFilter}
                            remainingFilteredCategories= {remainingFilteredCategories}
                        />
                    </div>
                </>
            )}

            {firstLine <= 4 &&(
                <>
                    <div className={styles.group}>
                        <FiltersSelected 
                            clickedFilteredCategories={clickedFilteredCategories} 
                            removeFilter={removeFilter}
                            remainingFilterIsEmpty={remainingFilterIsEmpty}
                        />
                                
                        <FiltersRemainingFirst 
                            addFilter={addFilter}
                            remainingFilteredCategories= {remainingFilteredCategories}
                        />
                    </div>
                </>
            )}  
        </>
    )
}

export default FiltersSmartphone